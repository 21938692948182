import React from 'react'
import { makeStyles } from '@material-ui/core'
import theme from '../../theme'

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '250px',
        backgroundImage: props => props.img,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        cursor: 'pointer',
        borderRadius: '10px',
        color: '#fff',
        '&:hover': {
            '& #DisplayOver': {
                transform: "translate3d(0,0,0)",
                backgroundColor: 'rgba(0,0,0, .5)'
            },
            '& #hover': {
                opacity: 1,
                color: '#fff'
            },
            '& #title': {
                opacity: 1,
                color: '#fff'
            }
        },
    },
    DisplayOver: {
        height: '100%',
        left: "0",
        position: "absolute",
        top: "0",
        width: "100%",
        borderRadius: '10px',
        zIndex: 2,
        transition: "background-color 350ms ease",
        backgroundColor: "transparent",
        padding: "20px 20px 0 20px",
        boxSizing: "border-box",
    },
    title: {
        textTransform: "uppercase",
        fontFamily: "Roboto",
        color: '#fff',
        opacity: 0,
    },
    hover: {
        opacity: 0,
        transition: "opacity 350ms ease",
        padding: `0 ${theme.spacing(1)}px`,
        display: 'flex',
        justifyContent: 'center'
    },
    subText: {
        transform: "translate3d(0,50px,0)",
        transition: "transform 350ms ease",
    },
    cta: {
        position: "absolute",
        bottom: "20px",
        left: "20px",
    },
    paragraph: {
        fontFamily: 'Roboto',
    }
})
const Portfolio = ({ img, text, name }) => {
    const props = { img: `url(${img})` }
    const classes = useStyles(props);
    return (
        <div className={classes.root}>
            <div id='DisplayOver' className={classes.DisplayOver}>
                <h5 id='title' className={classes.title}>{name}</h5>
                <div id='hover' className={classes.hover}>
                    <p className={classes.paragraph}>{text}</p>
                </div>
            </div>
        </div >
    )
}

export default Portfolio
