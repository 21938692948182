import React from "react"
import Layout from "../components/Layout/layout"
import Squares from "../components/Squares/Squares"
import { Container, Grid, Typography } from "@material-ui/core"
import PorfolioCard from "../components/Portfolio/Portfolio"
import { makeStyles } from "@material-ui/core"
import { motion } from "framer-motion"
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    margin: 8,
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
})
const projects = [
  {
    name: "Simple Soul AZ",
    img: "https://i.imgur.com/bk73ozA.png",
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo similique nulla pariatur.",
    link: "https://simplesoulaz.com",
  },
  {
    name: "Leading Edge Drywall",
    img: "https://i.imgur.com/cidPphh.png",
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo similique nulla pariatur.",
    link: "www.leadingedgedrywallaz.com",
  },
  {
    name: "Thrive Auto Detailing",
    img: "https://i.imgur.com/iwca5Ll.png",
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo similique nulla pariatur.",
    link: "https://thriveautodetail.com",
  },
]

const arrayGroupSplit = (arr, groupSize) => {
  let groups = []
  const dividen = Math.ceil(arr.length / groupSize)
  for (let i = 0; i < arr.length; i += groupSize) {
    groups.push(arr.slice(i, i + groupSize))
  }
  return groups
}

const rows = arrayGroupSplit(projects, 3)
const PortfolioPage = () => {
  const classes = useStyles()
  return (
    <Layout>
      <Squares>
        <Container>
          <main>
            <Grid container>
              <Grid item xs={12} style={{ paddingBottom: "3.5em" }}>
                <Typography variant="h3">our</Typography>
                <Typography
                  variant="h3"
                  gutterBottom
                  style={{ color: "rgb(37,137,124)" }}
                >
                  work
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} justify="center">
                  <Grid item xs={12}>
                    {rows.map((i, ind) => (
                      <>
                        <Grid className={classes.grid} key={ind} item xs={12}>
                          {i.map((item, index) => (
                            <motion.div
                              whileHover={{ flexGrow: 2 }}
                              className={classes.root}
                            >
                              <a
                                key={index}
                                href={item.link}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <PorfolioCard
                                  key={index * -1}
                                  img={item.img}
                                  text={item.text}
                                  name={item.name}
                                />
                              </a>
                            </motion.div>
                          ))}
                        </Grid>
                      </>
                    ))}
                    {/*  */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </main>
        </Container>
      </Squares>
    </Layout>
  )
}

export default PortfolioPage
